.authentication {
  min-height: 100vh;
  display: flex;
  background-color: $background;

  .navigation {
    a {
      margin-left: 10px;
      text-decoration: underline;
    }
  }
}

.authentication__brand {
  margin-right: 100px;
  padding: 35px 40px;
  width: 30%;
  max-width: 500px;
  display: flex;
  background-color: $brand;
  background-image: url('../../images/authentification.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  .logo {
    width: 45px;
    height: 45px;
    background: center url('../../images/logo-white.svg') no-repeat;
    background-size: contain;
  }
}

.authentication__content {
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;

  .navigation {
    position: absolute;
    top: 30px;
    right: 45px;
  }

  header {
    margin-bottom: 30px;
  }

  header,
  form {
    width: 650px;
    min-width: 600px;
  }

  h1 {
    margin: 0 0 20px;
    font-family: 'Aktiv Grotesque Bold', sans-serif;
    font-size: 3.4rem;
  }

  .validation {
    margin-top: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: $screen-xxl) {
  .authentication__brand {
    width: 20%;
    margin-right: 50px;
  }
}

@media (max-width: $screen-lg) {
  .authentication {
    flex-direction: column;
  }

  .authentication__brand {
    width: 100%;
    max-width: unset;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    color: #fff;
    background: none;

    .logo {
      background-image: url('../../images/logo.svg');
    }
  }
  .authentication__content {
    padding: 30px 15px 85px;
    justify-content: flex-start;

    h1 {
      margin: 0 0 20px;
      font-size: 2.4rem;
    }

    header {
      margin-bottom: 15px;
    }

    header,
    form {
      width: 100%;
      min-width: unset;
    }

    .navigation {
      padding: 0 15px 15px;
      width: 100%;
      top: unset;
      right: unset;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;

      a {
        margin: 0;
      }
    }
  }
}

@media (max-width: $screen-sm) {
  .authentication__content {
    .validation {
      margin-top: 0;
      flex-direction: column;
      align-items: flex-start;

      .btn {
        margin: 30px auto 0;
        width: 100%;
        max-width: 250px;
        display: flex;
        justify-content: center;
      }
    }
  }
}
